import React from 'react';
import Routes from "./RoutesDOM";
import './main.css';
function App() {
  return (
    <div>
    <Routes />
    </div>
  )
}

export default App;
